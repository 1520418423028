.informationItem__main .kipper_button{
    width: 80%;
    margin-left: 10% !important;
}
    .informationItem__main .boxButtons button{
        width: 90% !important;
    }
    .informationItem__main .withoutDocuments-text{
        
    }

@media(max-width:540px){
    .informationItem__main .boxButtons{
        display: flex;
        flex-direction: column;
    }
        .informationItem__main .boxButtons button{
            width: 100% !important;
            margin: 0 !important;
        }
        .informationItem__main .boxButtons button:last-child{
            margin-bottom: 10px !important;
        }
    .informationItem__main .kipper_button{
        width: 90% !important;
        margin-left: 0% !important;
    }
}