.sidebar{
    height: 95vh;
    width: 20%;
    position: fixed;
    background-color: white;
}
.sidebar__header{
    background-image: url('../Img/Layout/background_SideBar.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 20%;
    width: 100%;
}
.sidebar__headerTutor{
    background-image: url('../Img/Layout/background_SideBarOrangeDark.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 20%;
    width: 100%;
}
.sidebar__headerTeacher{
    background-image: url('../Img/Layout/background_SideBarOrange.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 20%;
    width: 100%;
}
.sidebar__headerChild{
    background-image: url('../Img/Layout/background_SideBarBlue.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 20%;
    width: 100%;
}
#app_back{
    background-image: url('../Img/Layout/background_App.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow-y: scroll;
}

#app_back-login{
    background-image: url('../Img/Layout/background_app-out.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    margin: 0 !important;
    opacity: 0.9;
}
.out-link{
    color: white;
    padding: 1rem;
}
.sidebar_icon{
    width: 25px;
}
li.bellinsidebar{
    margin-right: 2vw;
}
@media (max-width: 959.99px){
    #app_back{
        height: 90vh;
    }
}

.tab_btn{
    padding: 15px 25px 25px 25px!important;
    margin: 0;
    margin-right: 5px;
    border-top-left-radius: 8px!important;
    border-top-right-radius: 8px!important;
    outline: none!important;
    height: 60px;
    background: rgba(0,0,0,0.04);
    padding: 18px 8px;
    margin-bottom: -14px!important;
    padding-bottom: 18px!important;
    background-color: rgba(0,0,0,0.2);
}
.tab_btn.active:hover{
    background-color: white;
}
.tab_btn>span{
    text-transform: capitalize;
    color: var(--color_primary);
}
.tab_btn.active{
    background: #FFF!important;
    height: 60px;
    box-shadow: -3px -4px 3px -3px rgba(0,0,0,0.2);
}