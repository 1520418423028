/*Modal date*/
div[class^="makeStyles-text-"]{
    display: none !important;
}
div[class^="makeStyles-text-"]:first-child {
    display: block !important;
}
/*Schedulers numbers days*/
div[class^="Cell-dayOfMonth-"] {
    display: none !important;
}