#materiaItem{
    /* height: 130px; */
}
    #materiaItem h4{
        font-size: 18px;
        width: 100%;
    }
    #materiaItem i{
        float: right;
    }

@media(max-width:959px){
    #materiaItem{
        /* height: 230px; */
    }
    #materiaItem h4{
        text-align: center;
        height: 130px;
    }
    #materiaItem i{
        float: none;
        text-align: center;
        width: 100%;
    }
}