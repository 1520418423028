.msg_body.blue {
    /* background: #03BBDA; */
    border-radius: 11px;
    padding:8px;
    display: flex;
    justify-content: flex-end;
    
    /* max-width: 70%; */
}
.msg_content.grey>div{
    justify-content: flex-end;
    display: flex;
    background: #9e9e9e;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
}
.msg_body.blue>div{
    justify-content: flex-end;
    display: flex;
    background: #03BBDA;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
}
.msg_body.lowblue {
    
    border-radius: 11px;
    padding:8px;
    display: flex;
    justify-content: flex-start;
}
.msg_body.lowblue>div{
    background: #EFF4F8;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
}
.msg_content{
    padding-top: 20px;
    display: flex;
    align-items: center;
}
.msg_kipper{
    padding-top: 20px;
    display: flex;
    align-items: center;
}
.msg_text{
    color: #FFF;
}
.msg_text.dark{
    color: #000;
}
.textarea_content{
    width: 100%;
}
.textarea_content>div{
    width: 100%;
    padding: 0px 10px 0px 20px;
}

.submit_content>a, .submit_content>a:hover{
    width: 100%;
    background: var(--color_primary);
    border-radius: 30px;
    height: 42px;
    text-transform: capitalize;
    color: #fff;
    font-size: 18px;
}

.form_control_chat{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #EFF4F8;
    border-radius: 30px;
    display: flex;
    align-items: center;
    height: 45px;
    width: 98%;
}
.btnSendMessage{
    background-color:#03BBDA !important;
    color: white !important;
    width:90%;
    padding-left:10px; 
    padding-right:10px;
    border-radius:40px;
    height: 43px;
}
@media all and (max-width: 520px) {
    .listNameChat{
        padding-left:20%;
    }
}
.card {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 300px;
    margin: 1rem;
    position: relative;
    width: 300px;
  }
  
  .card-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  .card-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  
.subtitle-content > div {
    /* padding: 20px 0px!important; */
    padding: 0px 0px 20px 0px !important;
    display: flex;
    align-items: center;
  }
.textarea_content>div+div::before{
    border-bottom: none;
}

.avatar_chat{
    width: 80px;
    height: 80px;
    border-radius: 50px;
    object-fit: cover;
}

@media(min-width: 320px)and (max-width: 540px){
    .avatar_chat{
        width: 100%;
        height: 60px;
    }
    .avatar_chat_list{ 
        min-height: auto;
    }
}
@media(min-width: 541px)and (max-width: 1023px){
    .avatar_chat_list{ 
        min-height: 80px;
    }
}
@media(min-width: 1024px){

}

.msg_content.right{
    float: right;
}
.chat_content{
    min-height: 60vh;
    max-height: 62vh;
    overflow: auto;
    overflow-x: hidden;
}
.chat_content>div{
    padding: 8px!important;
    display: flex;
}
.avatar_content{
    display: flex;
    justify-content: center;
}
.msg_content>div{
    margin-bottom: 10px;
}
@media( min-width:1100px ){
    .msg_content{
        max-width: 70%;
    }
}
@media(min-width: 320px)and (max-width: 540px){
    .form_control_chat{
        height: 42px;
        width:93%
    }
}
.chatHeader{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left:15px;
    padding-top:15px
}
@media(min-width: 320px)and (max-width: 540px){
    .btnSendMessage{
        height: 40px;
    }
}

