/* Global styles */
body{
    overflow-x: hidden;
}




/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 10px;
    top:13px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: white;
    padding: 1.5em .5em 0;
    font-size: 1.15em;
    z-index: 60000;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .burgerMenuItem_container{
    width: 100%;
    background-color: white;  
    display: flex;
    margin-bottom: 15px;
  }

  .burgerMenuItem_container h1{
    color: #2f3233;
  }

  .burgerMenuItem_container img{
    margin-right: 20px;
  }








.TutorChild__title{
    color: gray;
    font-size: xx-large;
    padding-top: 2%;
}

#menuResponsive{
    display:none;
}

.homeTutor_headerInformationContainer{
    display: none;
}

/*Chat*/
#ConversacionesMaestro__buttonHeader{
    margin-right: 1%;
}
#ConversacionTutor__buttonHeader{
    padding-top:20px;
    padding-right:10px;
}
.Conversaciones__Boxicon{
    text-align: right;
}
.SeleccionarChat__Boxicon{
    margin-right: 1%;
}

.Conversaciones__buttons{
    padding-right: 2%;
}

.MuiContainer-root{
    margin-bottom: 13px;
}

.conversacionesMaestro__hoverPointer, .conversacionesSchool__hoverPointer, .conversacionesTutor__hoverPointer, .conversacionesAlumno__hoverPointer{
    padding: 2px 0px 2px 0px;
    margin-top: 2px;
    height: 120px;
}
    .conversacionesMaestro__hoverPointer:hover, .conversacionesSchool__hoverPointer:hover, .conversacionesTutor__hoverPointer:hover, .conversacionesAlumno__hoverPointer:hover{
        background-color: #E2E2E2;
        cursor: pointer;
    }
.avatar_chat{
    border-radius: 50px;
    width: 80px;
    height: 80px;
    object-fit: cover;
}

:root {
    --color_primary: #03BBDA;
    --color_yelow: #F8B236;
    --color_secundary: #8FEBF5;
    --color_grey: #707070;
    --color_green: #69BB59;
    --calendar_green: #378006;
    --calendar_blue: #0000FF;
    --calendar_orange: #ff9326;
    --content: #5B5E5F;
    --placeholder: #E2E2E2;
    --successful: #01C17B;
    --fail: #C10135;
}
.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
.kipper_title{
    color: var(--color_primary);
}
.kipper_txt-color{
    color: var(--color_primary);
    
}
.text-white{
    color: white;
}
.text-content{
    color: var(--content);
}
.text-placeholder{
    color: var(--placeholder);
}
.text-green{
    color: var(--color_green);
}
.color-blue{
    color: var(--calendar_blue);
}
.color-green{
    color: var(--calendar_green);
}
.color-orange{
    color: var(--calendar_orange);
}
.kipper_txt-grey{
    color: var(--color_grey);
}
.opacity-05{
    opacity: 0.5;
}
.Kipper_title-black{
    font-size: 1.2rem;
    font-weight: bolder;
}

#modalschool div.modal__Posotion.modal__Global div div div p.textMod_p{
    padding: 0 2.2vw;
}
@media(min-width: 200px)and (max-width: 330px){
    .Kipper_title-black{
        font-size: 0.5rem;
        font-weight: bolder;
    }
}
.flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
  }
  
  .flex-start { 
    justify-content: flex-start; 
  }
  
  .flex-end { 
    justify-content: flex-end; 
  }  
  .flex-center { 
    justify-content: center; 
  }  
.Kipper_title-listChat{
    font-size: 1.2rem;
    font-weight: bolder;
}
@media(min-width: 200px)and (max-width: 362px){
    .Kipper_title-listChat{
        font-size: 0.6rem;
        font-weight: bolder;
        margin-left: 8%;
    }
}
@media(min-width: 363px)and (max-width: 435px){
    .Kipper_title-listChat{
        font-size: 0.7em;
        font-weight: bolder;
        margin-left: 8%;
    }
}
.Kipper_email-list{
    font-size: 1rem;
    display: flex;
}
@media(min-width: 200px)and (max-width: 362px){
    .Kipper_email-list{
        font-size: 0.6rem;
        margin-left: 8%;
        word-wrap: break-word;
    }
}
@media(min-width: 363px)and (max-width: 435px){
    .Kipper_email-list{
        font-size: 0.7rem;
        margin-left: 8%;
        word-wrap: break-word;
    }
}
.boxheader{
    height: 130px;
}
.kipper_button{
    background-color: var(--color_primary) !important;
    color: white !important;
    padding: 10px !important; 
    margin: 0 0 0 -10px !important;
}
.kipper_button2{
    background-color: var(--color_primary) !important;
    color: white !important;
    padding: 10px !important; 
    margin: 0 0 0 0px !important;
    width: 100%;
}
@media(min-width: 200px)and (max-width: 362px){
    .kipper_button, .kipper_button2{
        background-color: var(--color_primary) !important;
        color: white !important;
        font-size: 10px;
        width: 100%;
    }
}

.kipper_button_w{
    background-color: var(--color_primary) !important;
    color: white !important;
}

.box_outline-primary{
    background-color: white;
    border: 2px solid  var(--color_primary);
    border-radius: 15px;
}
.kipper_button-outline{
    background-color: var(--color_primary) !important;
    color: white !important;
    margin: 5px !important; 
    margin: 0 5px !important;
}
.btn_size{
    width: 120px;
}
.kipper_btn-outline-primary{
    border: 1px solid var(--color_primary) !important;
    color: var(--color_primary) !important; 
    padding: 10px !important;
    margin: 0 5px !important;
}
.kipper_btn-outline-black{
    padding: 10px !important;
    margin: 0 5px !important;
}
@media(min-width: 200px)and (max-width: 320px){
    .kipper_btn-outline-black{
        height: 40px;
        
    }
}
.kipper_btn-outline-new{
    padding: 10px !important;
    margin: 0 5px !important;
    background-color: var(--color_primary) !important;
    color: white !important;
}
.kipper_btn-outline-new span{
    text-decoration: none !important;

}
@media(min-width: 200px)and (max-width: 330px){
    .kipper_btn-outline-new{
        height: 40px;
        width: auto;
        font-size: 0.2rem;
    }
}
.kipper_btn-outline-black-maestro{
    padding: 10px !important;
    margin: 0 5px !important;
    background-color: var(--color_primary) !important;
    color: white !important;
}
  
.card-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  .card-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
.kipperBox_content{
    border-radius: 15px;
    background-color: white !important;
    min-height: 50vh;
    padding-top: 0%;
}
#notif_admin_box{
    padding-top: 0;
}
#box_Admin_register{
    padding-top: 0;
}
#box_Admin_groups{
    padding-top: 0;
}

.kipperBox_documentName{
    padding:25px!important;
}
.ChatButtonDiv a:hover {
    text-decoration: none;
}
#ConversacionTutor__buttonHeader a:hover {
    text-decoration: none;
}

.kipperBox_portada-img{
    width: 100%;
}
.outline-box{
    border: solid 1px var(--color_grey);
    border-radius: 5px;
}
.kipperBox_content-upload{
    margin-top: -5%;
}
.profileAvatar{
    text-align: center;
    width: 250px;
    border-radius: 5%;
}
.kipperBox_content-upload>img{
    width:100px;
    height: 100px;
}
.kipper_upload-file{
    border: 2px dashed var(--color_primary);
    padding: 3% 0px;
}
.kipperBoxColor_content{
    background-color: var(--color_secundary);
    padding: 20px;
    border: 2px solid var(--color_primary);
    border-radius: 15px;
}
.box_tab{
    background-color: white;
    border-radius: 0px 10px 10px 10px;
}
.box_tab-active{
    background-color: white;
    color: var(--color_primary);
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    transition: background-color .3s ease;
}
.box_tab-inactive{
    background-color: var(--placeholder);
    color: var(--color_primary);
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    transition: background-color .3s ease;
}
.fw-200{
    font-weight: 200 !important;
}
.fw-500{
    font-weight: 500 !important;
}
.bg-white{
    background-color: white;
}
.bg-primary{
    background-color: var(--color_primary);
}
.aling_center{
    text-align: center !important;
}
/* Component Login */
#login_content-header{
    background-image: url('../Img/Layout/background_Login.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.login_header {
    min-height: 215px;
}
.Login_box{
    width: 20vw;
    min-height: 60vh;
    border-radius: 15px;
}

#Login__options{
    text-align: center;
}
#Login__alertBox{
    background-color: #FDEEEC;
    padding: 5% 2% 2.5% 2%;
}
    #Login__alert{
        font-size: small;
        color:#72342f;
        padding: 2.5% 2% 5% 2%;
    }

/*radioButtons-appoimnetDetailsTutor*/

.AppoinmentsDetails__enter{
    display: none;
}

.MuiRadio-colorPrimary{
    color:#03BBDA!important;
}

.MuiRadio-colorSecondary{
    color:red!important;
}

/*new page header*/

.pageHeader_notShowUser_container{
    border-radius: 10px;
    width: 100%;
    display: flex;
}

.pageHeader_notShowUser_textContainer{
    width: 70%;
    border-radius: 0px;
}

.pageHeader_notShowUser_textContainer h4{
text-transform:none!important;
}

.pageHeader_notShowUser_buttonsContainer{
    width: 30%;
    border-radius: 10px;
}

.pageHeader_notShowUser_buttons{
    height: 100%;
    border-radius: 10px;

    text-align: right;
    padding: 5%;
}

.pageHeader_notShowUser_buttons button{
    
    width: 45%;
    margin:1%
}

#pageHeader_buttonBlueKiper{
    background-color: #03BBDA;
    color: white;
}


/* LW - AL */
.rightheader_class div div div.pageHeader_notShowUser_buttons {
    text-align: end;
}

.arrow_end {
    text-align: end;
}

.box_w {
    margin-right: 1rem;
}

.txtHw{
    margin-top: 10px;
    text-align: center;
}

.imW{
    display: block; 
    margin: auto;
    margin-bottom: 10px;
}

.txtDescTitle{
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;  
}

.fonsty{
    font-family: "Courier New", Courier, monospace !important; 
    font-size: 0.9rem !important;
  }

.assist-w {
    text-align: center;
    margin-left: 6rem;
}
.cent-tx{
    text-align: center;
}

.disBl_w{
    display: inline-block;
}

.mar-w{
    margin-left: 3.5rem;
}

.asterisk-w{
    color:red;
    font-weight:bold
}

.error-w{
    color:red;
}

.fit {
    object-fit: cover;
}

/* Component calendario agregar */
.text_area-style{
    border: 1px solid #707070;
    min-width: 100% !important;
    padding: 5px;
    margin-top: 5px;
}
/* box info */
.box_info-content{
    border: 2px solid  var(--color_primary);
    border-radius: 20px;
}  
.box_info-content .boxDocuments{
    height: 120px;
}
.box_info-content .link_documento{
    font-size: 12px;
    font-family: arial;
} 
.box_info-content-img{
    width: 100%;
}
.box_info-upload-img{
    margin-top: -10%;
}
.boxinfo-img{
    height: 100%;
}
.box_media{
    max-height: 32vh;
}
.MuiGrid-item {
    padding: 0px !important;
}

/* Component schoolInfo */
.schoolInfo_title{
    color: var(--color_primary);
}
.home_logo{
    max-width: 150px;
    min-width: 150px;
    max-height: 200px;
    min-height: 200px;
    object-fit: cover;
}
/* input type file - style */
.pointer{
    cursor: pointer !important;
}
.fileUpload {
    display: none;
}

/*component AlumnoAgregar*/

#kipper_txt_dragDropDocArea{

    width: 80%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    text-align-last: center;

  }

 /* Component eventItem */
.EventBox_date {
    width: 80% !important;
    height: 70%;
    margin: 15% 0%;
    background-color: var(--color_primary);
    color: white;
}
.EventBox_date-day{
    font-size: 1.2rem;
    font-weight: 500;
}
.EventBox_date-mount{
    font-size: 1rem;
    font-weight: 400;
}
/* Component showcontacts */
.box_contact-content {
    overflow-y: scroll;
    max-height: 60vh;
}
.list_divider {
    background-color: #EFF4F8;
    padding: 5px 15px;
}
.showCont_grid {
    max-height: 60vh;
}
.list_divider {
    background-color: #EFF4F8;
    padding: 5px 15px;
}
.ShowContracts__notFound{
    text-align: center;
    font-size: large;
    color: rgb(173, 38, 38);
    margin: 20px;
}


/* Modal */
.modal__Global{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.modal__Posotion{
    position: absolute;
    width: 400;
    outline: none !important;
}
/* Component BoxInfo */
.boxHome{
    padding: 1.5rem;
    background-color: white;
    border-radius: 0 15px 15px 0;
}
.boxHome_Title{
    color: var(--color_primary);
}
.boxHome_Text{
    font-size: .9rem !important;
}
.BoxHome_lista {
    list-style: disc;
    margin-left: 1.5rem;
    font-size: .8rem;
}
.boxHome_button{
    background-color: #03BBDA !important;
}
.headerSection{
    padding: 1.5rem;
    background-color: white;
    border-radius: 0 15px 15px 0;
}
.headerSection_Title{
    color: #03BBDA;
}
.headerSection_Text{
    font-size: .9rem !important;
}

.headerSection_button{
    background-color: #03BBDA !important;
}
.headerSection_Img{
    background-color: #FCDB6E !important;
}
.ContentSection{
    padding: 1.5rem;
    background-color: white;
    border-radius: 0 15px 15px 0;
    border-radius: 15px;
    padding-bottom: 5%;
    /* min-height: vh; */
}
.entrega_img{
    max-width: 80px;
    min-width: 80px;
    max-height: 80px;
    min-height: 80px;
    border-radius: 50%;
}
.entrega_time{
    font-size: 1.6rem;
    font-weight: 600;
}
.ContentSection_Title{
    color: #03BBDA;
}
.ContentSection_Text{
    font-size: .9rem !important;
}

.ContentSection_button{
    background-color: #03BBDA !important;
}
.ContentSection_Img{
    background-color: #FCDB6E !important;
}
.notificacion_title{
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color_grey);
    margin-left: 5px;
}
.notificacion_date{
    font-size: 1.2rem;
    font-weight: 600;
    color: bold var(--color_grey);
    margin-left: 5px;
}
.notificacion_title-body{
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color_primary)
}
.new_item{
    background-color: var(--color_secundary);
    padding: 20px;
    border-radius: 15px;
}

/* Asistencias */
.assistenceItem_IMG{
    max-width: 60px;
}

.kipperBox_content-upload { position: relative; }
.kipperBox_content-upload img.rounded { display: block; }
.kipperBox_content-upload .icon_pencil { 
    position: absolute; 
    bottom:0; 
    left: 10px;
    width: 20%;
    height: auto;
}
.color_black input{
    color: black;
}
#header_homework_details div div h4{
    text-transform: initial;
}

#buttonDetHomework{
    width: 90%!important;
    
}

#buttonDelHomework{
    margin-left: 5%!important;
    width: 90%!important;
}

#header_info_group{
    max-width: 100%;
    flex-basis: 100%;
}

#header_info_group div div h4{
    text-transform: initial;
}
#details_events_header div div h4{
    text-transform: initial;
}
.Appoiment_Flag{
    color: white;
    height: 2.75rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
}
.Appoiment_Flag span{
    text-transform: uppercase;
}
.Appoiment_Flag.denied{
    background-color: red;
}
.Appoiment_Flag.aproved{
    background-color: green;
}
.Appoiment_Flag.pending{
    background-color: orange;
}
#reasondiv div{
    width: 75%;
}
#button_Details{
    text-align: center;
}
.clickeable_class{
    cursor: pointer;
}
.mar_r_2{
    margin-right: 1rem;
}
.mar_r_2_i{
    margin-right: 1rem !important;
}

.end_chat_contactList{
    place-content: flex-end;
}
.PageHeader_toRight div div div.pageHeader_notShowUser_buttons{
    text-align: right;
}
.padding_left_events{
    padding-left: 18px;
}
.modalbuttonFix{
    margin: 0 !important;
}
.confirmAppointment{
    margin: 0 !important;
    padding: 11px !important;
}
.reasonInput{
    padding-top: 1rem;
}

/*componente asistencias, usuario maestro*/

.box_asistencias_messages{
    padding:10px
}

/*componentes "Information"*/

.informationComponentContainer{
    padding-top: 40px;
    margin-left: 30px;
    width: 30%;
}

.information_schoolTitle{
    font-size: 1.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
    color:#03BBDA;
}

.information_locationDataContainer{
    display: flex;
    margin-top: 0%;
    margin-bottom: 0%;
    text-align: center;
    font-family:"Roboto", "Helvetica", "Arial", sans-serif;
}

#tutorInformation_gridMisionVision{
    max-width: 50%!important;
    flex-basis: 50%!important;
}

#img_informationIcons{
    margin-top: 5px;
    width: 12px;
    height: 15px;
}

.information_misionVisionParraf{
    margin-bottom: 20px!important;
    font-size: 0.8rem!important;
    text-align: justify;
}

#iconArrowFaqs{

}
.FaqContent__General{
    padding: 2vh 2vw;

}
.kipperBox_content_Faqs{
    border-radius: 15px;
    background-color: white !important;

}



.FaqContent__General.MuiPaper-elevation1{
    box-shadow:none !important;
}
#Enviar_button_registerUser{
    margin: 0 !important;
}

@media (min-width: 960px){
    .alumn_grid{
        margin-left: -2%;
    }
}
@media (min-width: 960px){
    .entregas_grid{
        margin-left: -2%;
    }
}
@media (min-width: 960px){
    .alumInfo_grid{
        margin-left: -2%;
    }
}
@media (min-width: 960px){
    .padres_grid{
        margin-left: -2%;
    }
}
@media (min-width: 960px){
    .add_padreGrid{
        margin-left: -2%;
    }
}
@media (min-width: 960px){
    .contactReference_grid{
        margin-left: -2%;
    }
}
@media (min-width: 960px){
    .addReference_grid{
        margin-left: -2%;
    }
}
@media (min-width: 960px){
    .sede_grid{
        margin-left: -2%;
    }
}
@media (min-width: 960px){
    .delList_grid{
        margin-left: -2%;
    }
}
@media (max-width: 1460px){
    
}
/* SEDE */
.mb-3{
    margin-bottom: 50px !important;
}
.log-mb{
    margin-bottom: 10px !important;
}
.previw_img{
    width: 100px !important;
    height: 100px !important;
}
.event_img{
    margin-top: 15px ;
    width: 50% !important;
    height: 50% !important;
}
.alumnInfo_box{
    background-color: white
};
.showC_Grid {
    overflow-y: scroll;
    visibility: visible;
}
.showCont_grid {
    overflow-y: scroll;
}
.kipper_btn-outline-primary {
    width: 100%;
    margin-left: 1% !important;
}
.addedAlumn_button{
background-color: #03BBDA !important;
border-radius: 0 !important;
}
.AddAlumn_grid{
    background-color: white;
    border-radius: 1em;
    box-shadow: 5px 5px 5px grey;
}
.AddAlumn_grid img{
    width: 100%;
}
.textMod_button{
    color: white !important;
    text-transform: none;
}
.textMod_p{
    color: #959595 ;
}
@media (min-width: 1600px) and (max-width:1800px){
    .Login_box {
        width: 18vw;
    }   
}
@media (min-width: 1800px) {
    .Login_box {
        width: 16vw;
    }
}
.foot_grid {
        margin-top: -5em;
}
.box_contact{
    min-height: 40vh;
}
#profileIMGx{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
}
.chat_img-radius{
    border-radius: 50%;
    min-height: 100px;
}
@media (min-width: 1600px) and (max-width:1800px){
    .Login_box {
        width: 18vw;
    }
}
@media (min-width: 1600px) and (max-width:1800px){
    .sidebar {
        margin-left: 4em !important;    
    }   
}
@media (min-width: 1800px) and (max-width:1920px){
    .sidebar {
        margin-left: 7em !important;    
    }
}
@media (min-width: 1920px) and (max-width:2500px) {
    .sidebar {
        margin-left: 8em !important;
    }
}
.rounded{
    border-radius: 50%;
}
.d-none{
    display: none;
}
.img-tutor{
    max-width: 90px;
}
.img_home{
    border-radius: 50%;
    max-width: 65px;
}
.img_tutor-chil{
    max-width: 70px;
}
.calendar-top{
    border-radius: 10px 10px 0 0;
}
.calendar-bottom{
    border-radius: 0 0 10px 10px;
}
.calendar-bg-date{
    background-color: #ffe14b;
    padding: 5px;
    border-radius: 25px;
}
.center_center{
    display: flex;
    align-items: center;
}
.align-items-center{
    align-items: center;
    align-self: center;
}

.capitalize{
    text-transform: capitalize
}
.divider_materia{
    background-color: var(--placeholder);
    padding: 1px 15px;
}
.divider_materia-active{
    background-color: var(--color_primary);
    padding: 1px 15px;
}
.homework-pending{
    border-radius: 5px;
    padding: 5px;
    background-color: var(--placeholder);
    width: 100px;
    text-align: center;
}
.assistance-true{
    border-radius: 5px;
    padding: 5px;
    background-color: var(--successful);
    color: white;
    width: 100px;
    text-align: center;
}
.assistance-false{
    border-radius: 5px;
    padding: 5px;
    background-color: var(--fail);
    color: white;
    width: 100px;
    text-align: center;
}
.bubble-primary{
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    background-color: var(--color_primary);
    color: white;
    max-width: 5px;
    min-width: 5px;
    max-height: 5px;
    min-height: 5px;
}
.bubble-secondary{
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    background-color: var(--color_yelow);
    color: white;
    max-width: 5px;
    min-width: 5px;
    max-height: 5px;
    min-height: 5px;
}
.documents-download{
    border: 1px solid var(--color_primary);
    color: var(--color_primary);
}

/* Child */


#childHome_typographyTitle{
    width: 300px;
    
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#childHome_typographyDetails{
    width: 300px;
    height: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}



.GridChild_cont {
    padding: 1.5em;
}
.boxChild_button {
    margin-top: 2em !important;
    margin-left: 44em !important;
    background-color: #03BBDA !important;
    width: 10em;
}
.boxChild {
    margin-left: -4em !important;
}
.boxChild_Home {
    padding: 1em !important;
}
.box_childmedia {
    padding: 1em;
}
.fixbuttonchat{
    padding: 10px !important;
    margin: 0 5px !important;
}
.justify_cont_fend {
    justify-content: flex-end;
    text-align: -webkit-right;
}

.pad_left_1{
    padding-left: 15px;
}
.long_Text_cut{
    overflow: hidden;
    text-overflow: ellipsis;
}
.div_long_text{
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.boxGridContainer1{
    width: 90%;
}
.boxGridContainer2{
    width: 10%;
    text-align: right;
}
#TextMAteriasGrupos img{
    width: 15px;
    height: 15px;
}
#kipper_button_detailsChild_contactItem{
    width: 90%;
}
#kipper_button_deleteChild_contactItem{
    width: 90%;
}

@media (min-width: 1000px) and (max-width: 1300px) {
    .boxChild {
        margin-left: 0em !important;
    }
    .boxChild_button {
        margin-left: 18em !important;
    }
}
@media (min-width: 1300px) and (max-width: 1600px) {
    .boxChild {
        margin-left: 0em !important;
    }
    .boxChild_button {
        margin-left: 27em !important;
    }
}
@media (min-width: 1600px) and (max-width: 1920px) {
    .boxChild {
        margin-left: -2em !important;
    }
    .boxChild_button {
        margin-left: 37em !important;
    }
}
@media (min-width: 1920px) {
    .boxChild {
        margin-left: -2em !important;
    }
    .boxChild_button {
        margin-left: 44em !important;
    }
}
/*Reportes*/

@media (min-width: 1024px) and (max-width: 1500px) {
    .input_width {
        min-width: 13em;
    }
}
@media (min-width: 1500px)  {
    .input_width {
        min-width: 21em;
    }
}

.boxReports_width {
    width: 97% !important;
}

/* Teacher */

.evaluaciones_teacher {
    padding: 2em;
}
.evaluations_butt {
    margin-top: -11px;
}
/*evaluaciones*/

@media (min-width: 1514px) and (max-width: 1600px) {
    .partials_text {
        margin-left: 5em !important;
    }
}
@media (min-width: 1600px)  {
    .partials_text {
        margin-left: 13em !important;
    }
}
.evaluations_teacher {
    padding: 2em;
    background-color: white;
}
.image_evaluations {
    max-width: 100%;
    height: 3em;
    margin: 1em;
}

/*Estilos de EvaluacionesPromedio */
#Evaluaciones_parciales_container{
    border-radius: 12px;
}
    .evaluations_teacher select{
        width: 120px;
        margin-left: 2%;
        text-align: center;
        border: 0px;
    }
        .evaluations_teacher select option{
            text-align: center;
        }

/*Profile Tutor*/
.profile_icon{
    margin-bottom: 20px;
    color: rgb(56, 52, 52);
}

#withoutSubjects{
    margin: 30px;
}
/*Calendar*/
#calendar{
    margin: 20px 0px 20px 0px;
  }

.fc .fc-button-primary:disabled{
    margin-left: 12px;
  }

/*FAQ Tutor*/
.FaqContent__question{
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
}
