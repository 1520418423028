input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 320px){
  .chatbody_title_xs{
    width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .chatbody_img_xs{
    width: 50px !important;
    height: 50px !important;
  }
  .chatbody_block1_xs{
    width: 75%;
  }
  .chatbody_block2_xs{
    width: 25%;
  }
  .chatbody_term_xs{
    justify-content: center !important;
  }
  
  #app_back-login {
    opacity: 0.8 !important;
  }
  p.kipper_txt-color.imgConfigRes{
    font-size: 0.75rem !important;
  }
}
@media(min-width: 320px)and (max-width: 540px){/*Cel*/
  .AppoinmentsDetails__TutorName{
    margin-bottom: 13px !important;
  }
  .AppoinmentsDetails__Date{
    display: inline;
    font-size: medium !important;
  }
  #ConversacionesMaestro__buttonHeader{
    margin-right: 0%;
  }

  .sede__documentosDescargables{
    font-size: x-small;
  }


  /*es es exclusivamente para hardcodear el estilo de las casillas de los dias en el calendario NO MOVER FUERA DE ESTE MEDIA QUERY*/

  .fc .fc-daygrid-day-frame{
    position: relative;
    min-height: 32px;
    height: 10px;
  }

  #TutorChild__main{
    margin-top: 2%;
    align-items: center;
    padding-left: 14%;
  }
  .TutorChild__title{
    color: gray;
    font-size: xx-large;
    padding: 5% 0% 5% 15%;
  }

  /*NavTeacherCel*/

  #menuResponsive{
    display: block;

  }

  .bottomTeacherNav, .bottomSchoolNav{
    /* background-color: grey; */
    height: 50vh !important;
    display: flex;
    background-color: white;
  }

  .bottomChildNav, .bottomTutorNav{
    height: 50vh !important;
    display: flex;
    background-color: white;
  }

  .botomNav_itemContainer{
    width: 95px!important;
    padding-top: 25px;
    text-align: center;
    margin-right: 1% !important;
    display: block;
  }

  .botomNav_itemContainerChild, .botomNav_itemContainerSchool{
    width: 127px!important;
    padding-top: 25px;
    text-align: center;
    margin-right: 1% !important;
    display: block;
  }

  #botomNav_item1{}

  .botomNav_itemContainer img, .botomNav_itemContainerChild img, .botomNav_itemContainerSchool img{
    width: 30px;
    height: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .botomNav_itemContainer h4, .botomNav_itemContainerChild h4, .botomNav_itemContainerSchool h4{
    color: grey;
    font-size: 13px
  }



  /*Home details*/

  .homeTutor_headerInformationContainer{
    width: 100%;
    display: flex;
  }

  #homeTutor_headerInformationContainerDesktop{
    display: none;
  }
  
  .homeTutor_headerDataContainer{
    margin-top: 5%;
    margin-left: 5%;
    background-color: ;
    font-size: 12px;
  }

  .homeTutor_tutorInformationContainer{
    height: 30px;
    margin-top: 0%;
    margin-bottom: 0%;
    display: flex;
  }

  .homeTutor_tutorInformationContainer img{
    margin-top: 5px;
    width: 20px!important;
    height: 20px!important;
  }

  #homeTutor_schoolTitle{
    background-color: ;
    font-size: 12px!important;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .homeTutor_misionVisionContainer{
    margin-top: 10%;
    margin-bottom: 15%;
    width: 100%;
    display: flex;
  }


  #homeTutor_childsItemsContainer{
    justify-content: space-around;
    display: none;
  }

  .homeTutor_misionSchool{
    text-align: left;
    width: 50%;
    height: 200px;

    text-overflow: ellipsis;
    overflow: hidden;
    
    display: -webkit-box;
  
    -webkit-box-orient: vertical;
    
  }

  .homeTutor_visionSchool{
    text-align: right;
    width: 50%;
    height: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .home_logo{
    max-width: 150px;
    min-width: 110px;
    max-height: 200px;
    min-height: 140px;
    object-fit: cover;

  }

  .schoolInfo_title{
    font-size: 13px!important;
  }

  #icon_schoolInfo{
    margin-top: 2%;
    width: 10px!important;
    height: 12px!important;
  }

  .flex.my-3{
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: !important;
  }

  .MuiBox-root .SchoolInformation__details{
    padding-top:5%!important;
    padding-left: 5% !important;;
  }
  .MuiBox-root .SchoolInformation__main{
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  /*Chat*/
  #ConversacionTutor__buttonHeader{
    padding-right:1px;
  }

  .SeleccionarChat__HeaderButton{
    padding: 0% !important;
    margin: 0% !important;
  }
  .SeleccionarChat__Boxicon{
    margin-top: 0px;
  }
  .Conversaciones__buttons{
    padding-right: 1%;
    margin-right: 2%;
  }
  .conversacionesMaestro__hoverPointer, .conversacionesSchool__hoverPointer, .conversacionesTutor__hoverPointer, .conversacionesAlumno__hoverPointer{
    height: 70px;
  }
  /*Calendar*/

  #calendar{
    margin: 0px;
    padding: 0px;
    height: 50%;
    margin-bottom: 20px;
  }

  #calendar .fc-toolbar-title{
    width: 190px;
    font-size: 1.6em;
  }

  .fc .fc-today-button{
    margin-left: 12px;
    width: 92px;
    margin-bottom: 10px;
  }

  .fc-toolbar-chunk{
    width: 100%;
    margin-left: 2%;
  }

  /*Profile*/

  #profile__dataContainer{
    text-align: center;
    margin-left: 2px;
  }

  #app_back{
    height: 93vh!important;
  }
  .Login_box{
    width: 80vw;
  }
  /* .MuiBox-root-28 {
    padding-left: 8vw !important;
  } */
  .MuiGrid-container.login_grid {
    width: 65vw !important;
    display: flow-root;
    margin-left: -1rem;
    }
  .Texto_login_links{
    font-size: .85rem !important;
  }
  
  #app_back-login.MuiGrid-spacing-xs-3 {
    width: 100%;
    opacity: 0.8 !important;
  }
  .Caja_inferior_login{
    display: contents;
  }
  .chatbody_term_xs{
    justify-content: center !important;
  }
  .out-link{
    padding: 1rem 20vw;
  }
  /*Iphone 5*/
  .chatbody_title_xs{
    width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .chatbody_img_xs{
    width: 50px !important;
    height: 50px !important;
  }
  .chatbody_block1_xs{
    width: 75%;
  }
  .chatbody_block2_xs{
    width: 25%;
  }

  /*home*/
  /* #kiper_school_cardHome{
    margin:15px!important;
    padding: 10px!important;
  } */

  #kiper_school_cardHome{
    display: none;
  }

  #kiper_school_cardHome_text{
    padding-left: 0%;
  }

  /* /grupos/informacion/groupID */

  #kiper_school_subjetListItem{
    width: 100%;
    height: 110px;
    padding-left: 5%;
  }

  #kiper_gridContainer{
    max-width: 90%!important;
  }
 
  #kiper_school_childsListItem{
    width: 13rem;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }


  /*componente eventoItem*/

  #eventoItem-blueButton span{
    
    font-size: .875rem!important;
}


/*componente childHomeItem*/

#childHome_gridSubjectItem{
  margin: auto!important;
}

#childHome_boxSubjectItem{
  width: 320px;
  margin-left:0%!important;
  margin-right: 0%!important;
  padding: 15px!important;
}

#childHome_gridAvatarItem{
  width: 30%!important;
  max-width:30%!important;
}

#childHome_gridTextItem{
  width: 70%!important;
  max-width: 70%!important;
}

#childHome_typographyTitle{
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#childHome_boxDetails{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#childHome_typographyDetails{
  height: 50px;
}


/*new page header*/

.pageHeader_notShowUser_container{
  border-radius: 10px;
  width: 100%;
  display: flex;
}

.pageHeader_notShowUser_textContainer{

  width: 70%;
  border-radius: 10px;
}

.pageHeader_notShowUser_buttonsContainer{
  width: 30%;

  border-radius: 10px;
}

.pageHeader_notShowUser_buttons{
  height: 100%;
  border-radius: 10px;

  text-align: center;
  padding: 1%;
}

.pageHeader_notShowUser_buttons button{
  font-size: 0.7rem;
  width: 90%;
  margin:5%
}

#pageHeader_buttonBlueKiper{
  background-color: #03BBDA;
  color: white;
  font-size: 0.7rem;
}




  /*escuela/tutor/editar/tutorID*/

  #kiper_editarTutor_textInput{
    margin: auto;
    width: 360px;
  }




  /* /escuela/materias/informacion/materiaID */

  #kiper_materiasDetail_gridTitulo{
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    
  }

  #kiper_materiasDetail_boxSelect{
    width: 290px;
    padding: 16px;
  }







  /* /escuela/materias/grado */

  #kipper_boxContainer_subject_contactItem{
    padding:5px;
  }

  #kipper_item_subject_contactItem{
    width: 80px!important;
    flex-basis: 80px;
    margin-bottom: 20px;
   
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }

  #kipper_item_subject_contactItem span{
    font-size: 0.9rem;
  }

  #kipper_button_deleteSubject_contactItem, #kipper_button_detailsSubject_contactItem{
    width: 20px!important;
    padding: 10px!important;
    margin: 0px!important;
    font-size: 0.4rem!important;

  }

  #kipper_button_deleteSubject_contactItem{
    margin-right: 15px!important;
    font-size: 0.65rem!important;
  }






  /*bottomMenu Movil*/
  .MuiBottomNavigation-root{
    overflow-x: scroll;
    height: auto!important;
    justify-content: flex-start!important;
  }

  .MuiBottomNavigationAction-root{
    width: 65px!important;
  }

  .MuiBottomNavigationAction-label{
    display: none;
  }
  
  .MuiButtonBase-root {
    min-width: 65px;
  }

  
  /**/


  #boxinfo-img-alumnos{
    max-height: 140px;
  }

  .boxHome_Title{
    font-size: 1.4rem!important;
  }

  #boxHome_Text_Alumnos{
      font-size: 0.75rem!important;
      margin: 1rem 0;
  }

  .boxHome_button{
      font-size:0.6rem!important;
  }


  /*lista de alumnos Contactitem*/
  #profileIMGx{
      width: 50px!important;
      height: 50px!important;
      border-radius: 50%;
      padding:5%;
      margin-left: 5px;
  }

  .Kipper_title-black{
      font-size: 1rem;
  }

  #kipper_name_student_contactItem{
    font-size: 0.9rem!important;
    
  }

  #kipper_info_student_contactItem{
      font-size: 0.8rem!important;
      display:none!important;
  }


  #kipper_gridContainer_contactItem{
    max-width:90px ;
  }


  #kipper_button_detailsChild_contactItem{
    margin-right: 0px!important;
    font-size: 0.75rem!important;
    margin-left: -10px !important;
  }

  #kipper_button_deleteChild_contactItem{
    margin-right: 0px!important;
    margin-left: -10px !important;
    font-size: 0.75rem!important;
    
  }

  #BoxHomeAlumnos{
    margin-bottom: 5vh;
  }


  /*lista de grupos ContactItem*/

  #kipper_groupName_groups_contactItem{
    font-size: 0.9rem!important;
  }

  #kipper_button_verGrupo_contactItem{
    font-size: 0.5rem!important;
  }



  #materiaItem{
    margin:5%;
    padding:5%;
    padding-left: 20%!important;
    padding-right: 20%!important;
    font-size: 1rem;
    justify-content: center!important;
    text-align: center;
  }

  #materiaItem .fw-500{
    font-size: 1rem;
    padding-left: 0%;
    width: 100%!important;
    text-align: center;
  }

  #NoPaddingPrimaria{
    padding-left: 18%;
  }

  #NoPaddingSecundaria{
    padding-left: 8%;
    text-align: center;
  }

  #NoPaddingPrimero{
    padding-left: 18%;
  }

  #NoPaddingSegundo{
    padding-left: 18%;
  }
  #NoPaddingTercero{
    padding-left: 20%;
  }

  #NoPaddingCuarto{
    padding-left: 25%;
  }

  #NoPaddingQuinto{
    padding-left: 25%;
  }

  #NoPaddingSexto{
    padding-left: 25%;
  }
  /**/



  #kipper_button_addEvent{
    width: 100% !important;
    height: 100% !important;
    font-size: 1rem !important;
  }

  #kipper_button_addEvent span{
    font-size: 0.75rem;
  }

  /**/

  .aling_center span{
    font-size: 0.8rem!important;
  }

  .AdminNavbar{
    height: 14vh !important;
  }

  .AdminNavbar a{
    width: 20vw !important;
  }
  .bottonTeacherNav{
    height: 13vh !important;
  }
  .bottonTeacherNav a {
    width: 14vw !important;
  }
  .bottonTeacherNav a svg{
    font-size: 200% !important;
  }
  .kipper_button{
    font-size: .45rem !important;
    width: 25vw !important;
    margin: 0 !important;
  }

  .kipper_button_w{
    font-size: .25rem !important;
    width: 25vw !important;
    margin: 0 !important;
  }

  .box_contact-content .MuiBox-root{
    padding: 1rem 0 !important;
  }


  /* /sede */

  #kipper_sede_button_changeDocument{
    font-size: 0.6rem !important;
    width: 100% !important;
  }


  



  /*TUTORES*/

  #kiper_tutor_alumno_alumnoItem{
    width: 17rem;
    margin:10px;
    padding: 15px;
  }

  #kiper_tutor_alumno_alumnoItem img{
    border-radius: 100px;
    padding-right: 10px;
  }

  #kiper_tutor_alumno_alumnoNameItem {

    width: 7rem;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;

  }

  #kiper_tutor_alumno_tutorItem{
    justify-content: left;
    padding: 0px;
  }

  #kiper_tutor_alumno_tutorItem b{
    padding-left: 0%!important;
  }

  #kiper_tutor_alumno_tutorNameItem h6{
    width: 11rem;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }

  #kiper_tutor_alumno_teacherItem{
    padding-left:26px;
  }

  #kiper_tutor_alumno_teacherItem h6{
    width: 11rem;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }

  #kiper_tutor_alumno_materiaItem{
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }

  #kiper_tutor_alumno_materiaItem h6{
    width: 11rem;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }

  #kiper_tutor_cita_gridContainer{
    margin-left:0.5%; 
  }

  #kiper_tutor_cita_childItem{
    padding:0px;
    margin-bottom: 5%;
  }

  #kiper_tutor_cita_childItem h6 {
    width: 8rem;
    /* LW */
    /* overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis; */
    font-size: 1rem;
  }

  #kiper_tutor_cita_childItem p{
    font-size: 0.7rem;
  }
  

  #kiper_tutor_cita_selectTeacher_gridContainer h6{
    width: 8rem;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:clip;
    font-size: 1.3rem;
    margin-top: 10%;
  }
  .kipper_button span{
    font-size: .55rem;
  }

  .kipper_button_w span{
    font-size: .95rem;
  }


  button.kipper_button.FullWidthButton{
    width: 100% !important;
  }
  .boxChild h5{
    font-size: .6rem;
  }
  .promedy_text p{
    font-size: .7rem !important;  
  }
  .promedy_textDIV{
    overflow: hidden !important;
  }
  .partials_text{
    overflow: hidden !important;
  }
  .partials_text p{
    font-size: .7rem !important;  
  }
  .evaluations_teacher{
    padding: .6rem;
  }
  .boxChild{
    margin-left: 0 !important;
  }
  .inputEvaluations{
    width:1.5rem;
    height:2rem; 
    border:1px solid gray; 
    margin:2px; 
    text-align: -webkit-center;
  }
  .EvaluationsProm{
    width: 2rem;
    height:2rem; 
    border:1px solid gray; 
    margin:2px; 
    text-align: -webkit-center;
  }
  .partials_text{
    align-self: center;
  }

  .HomeTittle span{
    font-size: .75rem;
  }
  #buttonDetHomework{
    width: 100% !important;
  }
  #buttonDelHomework{
    width: 100% !important;
  }
  .image_evaluations{
    margin: 1em 0;
  }
  .boxReports_width {
    width: 100% !important;
  }
  .margin_button{
    margin: 1rem 0 !important;
  }
  .center_margin_button{
    margin: 1rem 20vw!important;
  }
  .center_margin_button a{
    margin-right: 10vw;
  }
  .MuiBox-root-340 {
    margin: .5rem !important;
  }
  .kipper_btn-outline-primary span{
    font-size: .55rem;
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.kipper_button.MuiButton-fullWidth{
    width: 100% !important;
  }
  .Form_add-profile-img img.rounded.preset{
    width: 100%;
  }
  .marginb_10px{
    margin-bottom: 10px !important;
  }
  .modal__Posotion{
    width: 90vw;
  }
  .Img_profile_tutor{
    width: 100% !important;
    height: 100% !important;
  }
  .Dato_Prof_Tutor{
    display: inline !important;
  }
  #link_homework-list a{
    overflow-wrap: break-word;
    text-decoration-line: underline;
  }
  #name_homework-list p{
    text-align-last: center;
  }
  #header_homework_details div div.margin_button button span{
    font-size: .85rem;
  }
  #List_Group div div div div div.List_showContracts div div div div.MuiBox-root div div#Div_Group a button {
    width: 95% !important;
  }
  #header_documents{
    padding: 1rem;
  }
  .Appoiment_Flag {
    font-size: .55rem !important;
    height: 2.2rem;
  }
  .EventBox_date {
    height: 80%;
  }
  #header_info_group div div.margin_button button.kipper_button span{
    font-size: 0.875rem !important;
  }
  .h5_responsive{
    font-size: 1.2rem !important;
  }
  .fixbuttonchat {
    padding: 6px !important;
  }
  .ChatButtonDiv{
    padding: 24px 0px !important;
  }

  /*Vista de evaluaciones parciales*/
  #evaluations_teacher_box{
    padding-left: 16%;
  }
    #evaluations_teacher_box select{
      margin-left: 10%;
      width: 120px;
    }

  #evaluacionesPromedio_calificacion{
    text-align: center;
    align-items: center;
  }
    #evaluations_teacher_select{
        width: 130px !important;
        text-align: right!important;
        text-align-last:center;
        padding-bottom: 5px;
    }
        #evaluations_teacher select option{
            text-align: right !important;
        }
  .box_tab-active{
    font-size: smaller;
    padding: 10px !important;
    height: 2.6rem;
  }
  .box_tab-inactive{
    font-size: smaller;
    padding: 10px !important;
    height: 2.4rem;
  }
  .Box_Tabs_Appointments{
    height: 2.5rem;
    font-size: 0.75rem;
  }

  /* LW  */
  .box_w {
    margin-right: 0.4rem;  
    margin-left: 0.4rem;
  }

  body {
    overflow-y: hidden; 
    /* overflow-x: hidden; */
  }

  .txtDescTitle{
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;  
  }

  .fonsty{
    font-family: "Courier New", Courier, monospace !important; 
    font-size: 0.9rem !important;
  }

  .txtHw{
    margin-top: 10px;
    text-align: center;
  }

  .imW{
    display: block; 
    margin: auto;
    margin-bottom: 10px;

  }
  .assist-w {
   text-align: center;
   margin-left: 2rem;
   margin-bottom: 1rem;
  }

  .assitM-w{
    margin-bottom: 1rem;
  }

  .box_details{
    margin-top: 4px;
    margin-bottom: 14px;
    font-size: 0.85rem;
  }

  .mar-w{
    margin-left: 0px;
  }

  .padding_left_events{
    padding-left: 10vw !important;  
  }
  .EventBox_date {
    width: 90% !important;
  }
  .confirmAppointment{
    padding: .72rem !important;
    width: 120px !important;
  }

  .kipper_button.confirmAppointment span {
    font-size: .85rem !important;
  }
  p.kipper_txt-color.imgConfigRes{
    font-size: 0.75rem !important;
  }
  .columnResponsiveHalf{
    width: 50% !important;
  }


  /*menuResponsive*/

  .navTeacher_menuContainer, .navChild_menuContainer, .navTutor_menuContainer, .navEscuela_menuContainer{
    margin-top: -12px;
    margin-left: -5px;
    margin-right: -5px;
    width: 105%;
    height: 60px;
  }

  .navTeacher_menuContainer{
    background-color: #FBB752;
  }

  .navChild_menuContainer{
    background-color: #03BADA;
  }

  .navTutor_menuContainer{
    background-color: #EF6F32;
  }

  .navEscuela_menuContainer{
    background-color: #44C34E;
  }

  .navTeacher_iconsContainer{

    width: 50%;
    height: 100%;
    margin-top: 1%;
    color:#e2e8f0;
    padding-left: 25%;
    float: right;
    background-color: ;
    display: flex;
  }

  #navTeacher_logoKiper{
    width: 50%;
    height: 100%;
    color:#e2e8f0;
    padding-left: 5%;
    float: left;
    display: flex;
  }
  .sidebar_iconNav{
    margin-top: 20%;
    padding-left: 30%;
  }
  .sidebar_iconNavDehaze{
    margin-top: 5%;
    margin-left: 5%;
  }

  #calendarChild{
    display: none;
  }

}

@media(min-width: 541px)and (max-width: 1023px){

  .MuiBottomNavigationAction-label{
    display: none;
  }
  
  .MuiButtonBase-root {
    min-width: 0px!important;
  }
  #app_back-login {
    opacity: 0.8 !important;
  }
  .Login_box{
    width: 55vw !important;
  }
  .MuiGrid-container.login_grid{
    display: contents;
  }
  .Caja_inferior_login{
    display: flex;
    padding: 15vh 10vw;
  }
  .chatbody_term_xs{
    justify-content: center !important;
  }
  .Texto_login_links{
    font-size: .95rem !important;
  }
  .AdminNavbar{
    height: 14vh !important;
    overflow-x: scroll;
    justify-content: flex-start!important;

  }
  .AdminNavbar a{
    width: 20vw !important;
  }
  .AdminNavbar a .MuiBottomNavigationAction-root{
    min-width: 0px !important;
    width: 65px!important;
  }
  .bottonTeacherNav{
    height: 13vh !important;
  }
  .bottonTeacherNav a {
    width: 14vw !important;
  }
  .bottonTeacherNav a svg{
    font-size: 200% !important;
  }
  .boxChild h5{
    font-size: 1rem;
  }
  .promedy_text p{
    font-size: .7rem !important;  
  }
  .partials_text p{
    font-size: .7rem !important;  
  }
  .evaluations_teacher{
    padding: .6rem;
  }
  .boxChild{
    margin-left: 0 !important;
  }
  .EvaluationsProm{
    width: 2rem;
    height:2rem; 
    border:1px solid gray; 
    margin:2px; 
    text-align: -webkit-center;
  }
  .inputEvaluations{
    width:2rem;
    height:2rem; 
    border:1px solid gray; 
    margin:2px; 
    text-align: -webkit-center;
  }
  .partials_text{
    align-self: center;
  }
  .HomeTittle span{
    font-size: .75rem;
  }
  .image_evaluations{
    margin: 1em 0;
  }
  img.previw_img.rounded.preset{
    width: 100%;
  }
  .Dato_Prof_Tutor{
    display: inline !important;
  }
}

@media(min-width: 1024px){
  .padres_grid.teacherProfile39 .MuiButton-disableElevation {
    padding: 11px !important;
  }
  .padres_grid.teacherProfile39 {
    margin-left: 0;
    padding: 0 10px;
  }
  .padres_grid.teacherProfile39 .MuiBox-root.MuiBox-root-84.kipperBox_content {
    min-height: 13em;
  }
  .padres_grid.materiasB21 .MuiPaper-root.MuiPaper-elevation3 .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
      width: 109%;
      border-radius: 0;
  }
  .padres_grid.materiasB21 .MuiPaper-root.MuiPaper-elevation3.MuiPaper-rounded {
    border-radius: 12px;
  }
  .padres_grid.materiasB21 .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.kipper_button.MuiButton-fullWidth[type = "submit"] {
    border-radius: 0;
    border: 0;
    padding: 8px 15px !important;
  }
  
  .padres_grid.materiasB21 {
    padding: 0 10px;
    margin-left: 0em;
  }
  .MuiGrid-root.MuiGrid-grid-xs-4 {
    background: white;
  }
  .MuiBox-root.MuiBox-root-76.sidebar__header, .MuiBox-root.MuiBox-root-76.sidebar__headerTutor, .MuiBox-root.MuiBox-root-76.sidebar__headerTeacher, .MuiBox-root.MuiBox-root-76.sidebar__headerChild {
    border-radius: 16px;
  }
  .MuiBox-root.MuiBox-root-429.sidebar__header, .MuiBox-root.MuiBox-root-76.sidebar__headerTutor, .MuiBox-root.MuiBox-root-76.sidebar__headerTeacher, .MuiBox-root.MuiBox-root-76.sidebar__headerChild {
    border-radius: 16px;
  }
  .sidebar__header, .sidebar__headerTutor, .sidebar__headerTeacher,.sidebar__headerChild {
    border-radius: 16px;
  }
  .alumn_grid {
    margin-left: 0em;
  }
  .MuiBox-root.MuiBox-root-80.box_media {
    max-height: -webkit-fill-available !important;
  }
  .MuiBox-root.MuiBox-root-80.box_media .boxinfo-img.box_media {
    max-height: -webkit-fill-available !important;
  }
  .MuiBox-root.MuiBox-root-80.box_media .MuiGrid-root.MuiGrid-grid-xs-4 {
    background: white;
  }
  .sidebar .sidebar__search {
    padding: 0 2em;
  }
  .inputEvaluations{
    width:2rem;
    height:2rem; 
    border:1px solid gray; 
    margin:2px; 
    text-align: -webkit-center;
  }
  .evaluations_num{
    border:1px solid gray; 
    margin:2px; 
    padding: 2px;
    text-align: -webkit-center;
  }
  .partials_text{
    align-self: center;
  }
  .EvaluationsProm{
    width: 2rem;
    height:2rem; 
    border:1px solid gray; 
    margin:2px; 
    text-align: -webkit-center;
  }
  img.rounded.preset{
    width: 100%;
  }

  
}
/* @media(min-width: 1024px) and (max-width: 1660px){
  .sidebar {
    height: 47em !important;
  }
}
@media(min-width: 1660px) and (max-width: 2050px){
  .sidebar {
    height: 57em !important;
  }
}
@media(min-width: 2050px) and (max-width: 2560px){
  .sidebar {
    height: 68em !important;
  }
} */
